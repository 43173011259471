import getData from "@/__main__/get-data.mjs";
import * as GraphQLTemplates from "@/game-val/graphql-templates.mjs";
import type { AgentInlineEntry } from "@/game-val/models/article-inline-entry.mjs";
import { AgentInlineEntryModel } from "@/game-val/models/article-inline-entry.mjs";
import { queryContentful } from "@/shared/contentful-fetches.mjs";
import fetchContentfulArticle from "@/shared/fetch-contentful-article.mjs";

function getAgentByEntryId(entryId: string): Promise<AgentInlineEntry> {
  return getData(
    queryContentful(GraphQLTemplates.getValorantAgentInlineEntry, { entryId }),
    AgentInlineEntryModel,
    ["val", "cms", "entryInline", entryId],
  );
}

export default async function fetchData(props): Promise<void> {
  const entryIds = await fetchContentfulArticle(props);

  entryIds.map(getAgentByEntryId);
}
